@import '../base/color';

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 50px;
}

.loading-message {
  margin-top: 20px;
  color: $primary;
}
