@import '../../../../../../styles/main.scss';

.autocomplete {
  position: relative;
  width: 100%;
  color: $fontSecondary;
  height: 100%;
  max-width: 200px;
}

.input-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid $fontQuaternary;
  border-radius: 4px;
  height: 39px;
}

.autocomplete-label {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 15px;
  color: #999;
  transition: 0.2s ease all;
  pointer-events: none;
}

.input-wrapper.focused .autocomplete-label,
.input-wrapper .autocomplete-input:not(:placeholder-shown) ~ .autocomplete-label {
  top: 0px;
  left: 10px;
  font-size: 12px;
  color: $primary;
  background-color: $white;
  padding: 0 3px;
}

.autocomplete-input {
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  transition: border-color 0.2s ease;
  width: calc(100% - 20px);
  height: 100%;
}

.clear-icon {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 18px;
  color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
}

.autocomplete-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute; 
  right: 6px; 
  top: 50%; 
  transform: translateY(-50%);
  color: $fontTertiary;
  font-size: 15px;
}

.autocomplete-list {
  position: absolute;
  left: 0;
  right: 0;
  background-color: $white;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 100;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  @include scrollbar($primary, $white, 6px);
}

.autocomplete-option {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-size: 15px;

  &:hover {
    background-color: $autocompleteHover;
  }

  &:active, &.selected {
    background-color: $autocompleteActive;
  }
}
