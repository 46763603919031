@import '../base/color';
@import '../base/breakpoints';

.login {
    height: 100vh;
    
    @include custom(1920px) {
      background-position: center !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      width: 100vw !important;
      max-width: none !important;
    }
  
    & > div {

      .logo { 
        width: 300px;
        height: 51px;
      }
    }
  
    button {
      font-size: 16px !important;
      font-weight: 500 !important;
    }
}  