@import '../base/color';

.btn {
  font-weight: 500;

  @media screen and (max-width: 768px) {
    & {
      font-size: 14px !important;
    }
  }

  &.btn {
    line-height: 1 !important;
    padding: 10px 18px !important;
    color: $white;
    border-radius: 4px;
    border: none;
    white-space: nowrap;

    &:active {
      -webkit-transform: scale(0.98);
      transform: scale(0.98);
    }

    &-arrow {
      color: $fontSecondary !important;
      padding: 5px 10px !important;
    }

    &-sm {
      padding: 0.25rem 0.5rem !important;
      border-radius: 0.2rem !important;
    }

    &-block { width: 100%; }

    &-action {
      padding: 8px !important;
      background: $white;
      border: 1px solid $primary;
      border-radius: 6px;
      min-width: 38px;
    }

    &-add {
      display: flex;
      align-items: center;
      padding: 8px !important;
      background: $white;
      box-shadow: 0px 0px 20px rgba(171, 171, 171, 0.25);
      border-radius: 8px;
      color: $primary;
      border-radius: 8px;

      .add {
        background-color: $primaryLight;
        padding: 9px;
        margin-right: 15px;
        border-radius: 6px;
      }
    }

    &-default {
      i {
        font-size: 18px;
        color: $fontSecondary !important;
        line-height: 1.3;
      }

      @media screen and (max-width: 768px) {
        & { padding: 0.75rem 0.5rem; }
      }
    }

    &-info {
      background: $info;
    }

    &-warning {
      background: $warning;
    }

    &-danger {
      background: $danger;
    }
  }
}


.visor-pdf-btn {
  background-color: transparent;
  box-shadow: 2px 2px 10px $boxShadow;
  border-radius: 4px;
  padding: 2px 10px;
  color: $primary;
  position: absolute;
  top: 15px;

  &:hover {
    background-color: $background;
  }

  &:active {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
  }

  &:disabled {
    opacity: 0.7;

    &:hover {
      background-color: transparent;
    }
  }

  &__left {
    right: 94px;

    @include smMax {
      right: 60px;
    }
  }

  &__right {
    right: 25px;
  }

  @include smMax {
    span {
      display: none;
    }
  }
}