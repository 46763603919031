// styles/components/_stepper.scss

@import '../base/_color';

.stepper {
  .MuiStepLabel-label {
    color: $fontSecondary;
    
    &.Mui-active {
      color: $primary;
    }
    
    &.Mui-completed {
      color: $primaryHover;
    }
  }

  .MuiStepIcon-root {
    color: $primaryHoverBtn;
    
    &.Mui-active {
      color: $primary;
    }
    
    &.Mui-completed {
      color: $primaryHover;
    }
  }

  .MuiStepConnector-line {
    border-color: $primaryLight;
  }

  .MuiStepConnector-root {
    &.Mui-active,
    &.Mui-completed {
      .MuiStepConnector-line {
        border-color: $primary;
      }
    }
  }

  .MuiButton-root {
    background-color: $primary;
    color: $white;
    
    &:hover {
      background-color: $primaryHoverBtn;
    }
    
    &:disabled {
      background-color: $light-gray;
      color: $off-white;
    }
  }

  .MuiTypography-root {
    color: $fontPrimary;
  }
}
