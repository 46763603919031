@import '../../../../styles/base/color';

.metrics {
    column-gap: 15px;

    .box {
        background-color: $gray;
        border-radius: 16px;
        padding: 10px;
        
        h1 {
            font-size: 1.25rem;
            font-weight: 700;
        }
    }
}
