@import '../base/color';

.MuiTooltip-popper {
    & > * {
        border: 1px solid $primary !important; 
        background-color: $primaryLight !important;
        box-shadow: 0px 0px 20px rgba(171, 171, 171, 0.25);
        color: $primary !important;
    }
    

    small {
        font-size: 0.875rem !important;
        text-transform: uppercase;
    }
}