@import '../base/color';

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 1rem;

    label { margin-bottom: 5px;}
  }

  input::placeholder {
    color: $light-gray;
  }

  &-invalid { 
    color: $danger; 
    font-size: 0.9rem;
    padding: 2px 1px;
  }

}

.form-control:disabled, 
.form-control[readonly], 
select:disabled,
textarea:disabled,
.Mui-disabled {
  background-color: $off-white !important;
  opacity: .8 !important;
  color: #212529 !important;
  -webkit-text-fill-color: unset !important;
}