@import '../../../../styles/main.scss';

.vin {
  svg {
    cursor: pointer;
  }
  .vin-input-search {
    width: 100%;

    input {
      min-width: auto !important;
      border-bottom: 2px solid rgba(218, 218, 219, 0.627);
      border-radius: 0 !important;
    }
  }

  &__icons {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: $primary;
      gap: 6px;
      
      span {
        border: 1px solid;
        width: 35px;
        height: 35px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    
    }
}

.vin-scroll-container {
  @include scrollbar($light-gray, $white, 8px);
}

.vin-button-product-detail {
  width: 100%;
  padding-bottom: 32px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  button {
    color: $white;
    background-color: $primary;
    font-weight: 300;
    border: 1px solid $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 10px 16px;
    border-radius: 6px;

    &:hover {
      background-color: $primaryHoverBtn2;
    }

    &:active {
      transform: scale(0.995);
    }

    &:disabled {
      opacity: 0.8;

      &:hover {
        background-color: $primary;
      }

      &:active {
        transform: scale(1);
      }
    }
  }
}

.vin-dialog-select {
  .MuiSelect-select {
    padding: 7px !important;
    height: 24px !important;
    font-size: 0.875rem !important;
  }
}

.vin-text-field {
  input {
    min-height: 22px !important;
  }
}

.obspub, .obspri {
  textarea {
    border: none !important;
  }
}


.vin-save-btn {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 30px;
}
