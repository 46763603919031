body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bookmark {
  border-left: 5px solid;
  /* Puedes personalizar el color y el ancho del borde aquí */
  padding-left: 5px;
  /* Espacio a la izquierda del contenido del <tr> para mostrar el borde */
}

.MuiOutlinedInput-root {
  padding: 1px !important;
}

#historicFilters .MuiTextField-root {
  margin: -5px 0px 0px 0px !important;
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

#historicFilters .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
  padding: 7px 30px 7px 15px !important;
  color: #000 !important;
}

.btn-primary {
  --bs-btn-color: #FFF !important;
  --bs-btn-bg: #215732 !important;
  --bs-btn-border-color: #215732 !important;
  --bs-btn-hover-color: #FFF !important;
  --bs-btn-hover-bg: #1a4628 !important;
  --bs-btn-hover-border-color: #1a4628 !important;
  --bs-btn-focus-shadow-rgb: 66, 112, 81 !important;
  --bs-btn-active-color: #FFF !important;
  --bs-btn-active-bg: #1a4628 !important;
  --bs-btn-active-border-color: #194126 !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
  --bs-btn-disabled-color: #FFF !important;
  --bs-btn-disabled-bg: #215732 !important;
  --bs-btn-disabled-border-color: #215732 !important;
}

.btn-secondary {
  --bs-btn-color: #FFF !important;
  --bs-btn-bg: #525252 !important;
  --bs-btn-border-color: #7f7f7f !important;
  --bs-btn-hover-color: #FFF !important;
  --bs-btn-hover-bg: #414141 !important;
  --bs-btn-hover-border-color: #6c6b6b !important;
  --bs-btn-focus-shadow-rgb: 130,138,145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}


    