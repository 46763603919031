

@mixin scrollbar ($colorBar, $colorBg, $whidth:6px, $height:8px) {
  &::-webkit-scrollbar {
      width: $whidth;
      height: $height;
    }
    
  &::-webkit-scrollbar-track {
     background: $colorBg;
   }
    
  &::-webkit-scrollbar-thumb {
     background: $colorBar;
     border-radius: 2px;
   }
}

@mixin subtitle () {
  color: $primary !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 22px;
}

@mixin empresaFormBasics () {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  padding-bottom: 16px;

  @include sm {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@mixin basicBtn () {
  min-width: 120px !important;
  font-size: 14px !important;
}

