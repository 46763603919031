@import '../../../../../../styles/base/color';

.loading-backdrop {
    position: fixed;
    z-index: 9999;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;

    &-container {
        opacity: 1;
        transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 0;
        bottom: 0;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-tap-highlight-color: transparent;
        z-index: -1;
    }

    &-wrapper {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $white;
        border-radius: 12px;
        padding: 20px;
    }
}