@import '../../../../../../styles/base/color';

.navbar-sidebar {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    color: $primary;
    box-shadow: 1px 1px 3px $boxShadow;
    border-radius: 4px;
    padding: 5px 12px;
  }
}

.menu-bars {
  margin: 1rem;
  font-size: 1rem;
  background: none;
}

.notification {
  &-menu {
    margin: 1rem;
    font-size: 1.5rem;
  }

  &-icon {
    position: relative;
  }

  &-number {
    position: absolute;
    top: 10px;
    right: 7px;
    font-weight: 700;
    padding: 0 6px;
    border-radius: 50%;
    background: $danger;
    color: $white;
    font-size: 0.675rem;
  }
}

.nav-menu {
  background: $primary;
  height: 80px;
  width: 250px;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 999;
  transition: 850ms;
  overflow-y: auto;
  box-shadow: 4px 4px 15px $boxShadow;

  span.nav-text {
    font-weight: 400;
    padding: 0 15px;
  }

  &-notifications {
    background: $off-white;
    width: 350px;
    height: 100vh;
    display: flex;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 999;
    transition: 850ms;
    padding: 10px;
    overflow-y: auto;

    &.active {
      right: 0;
      transition: 350ms;
    }
  }

  &-items {
    padding: 0px;
    width: 100%;

    &__header {
      padding: 15px 15px 10px;
    }
  }

  &.active {
    left: 0;
    transition: 350ms;
  }

  .nav-text {
    text-decoration: none;
    color: $white;
    font-size: 0.875rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0 1px 10px;

    &.active,
    &:hover {
      background-color: $bgHover;
    }

    li {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px;
      list-style: none;
      border-radius: 6px;

      &:hover .aviso {
        color: #215732;
      }
    }
  }
}

.navbar-toggle {
  background: transparent;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#user-menu {
  a:link,
  a:visited,
  a:active {
    text-decoration: none;
    color: inherit;
  }
}
