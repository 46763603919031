#pdf {
    -webkit-print-color-adjust: exact;
    width: 100%;
    height: 100%;
    color: #32353e;
    margin: 0 !important;
    padding: 0 !important;
    font-family: Helvetica, sans-serif;

    .box { padding: 0 35px; }

    .header {
        width: 100%;
        margin-bottom: 15px;

        img {
            width: "auto";
            height: 20px;
            margin-bottom: 20px;
        }
    }

    h1 { 
        font-size: 36px !important; 
        font-weight: bold;
        color: #799a84 !important;
        margin: 30px 0;
    }

    h5 { 
        font-size: 18px !important; 
        font-weight: bold;
        margin: 0; 
        margin-bottom: 5px; 
    }

    p { 
        font-size: 14px !important; 
        line-height: 16px;
        margin: 0; 
        margin-bottom: 5px; 
    }

    .pedido-info  {
        margin-bottom: 15px;

        table {
            tbody tr td {
                vertical-align: middle;
            }

            .pedido-info-div {
                padding: 15px 10px; 

                &.green { background-color: #E8EEEA; }
                &.info-cliente { padding-left: 20px; }
            }
        }
    }

    .piezas {
        width: 100%;
        margin-bottom: 25px;

        table {
            width: 100%;
            border-top: 4px solid #215732;
            border-collapse: collapse;
    
            thead tr th { font-weight: bold; }
            thead tr th, tbody tr td { 
                text-align: left;
                padding: 10px;
                font-size: 14px !important; 
                border-bottom: 1px solid #e9e9e9;
            }
        }
    }

    .imagenes { 
        margin-bottom: 25px; 

        img { 
            width: auto; 
            height: 125px;
            margin-right: 15px; 
        }

        h5 {
            margin-bottom: 15px;
        }
    }

    .firma {
        margin: 10px 0; 

        img {
            margin-top: 10px;
            width: auto;
            height: 75px;
        }
    }

    footer { margin-top: 35px; }

    .footer {
        border-top: 3px solid #215732;
        padding: 10px 0;
    }

    a { color: #215732 !important; }
}

