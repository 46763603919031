@import '../../../../../../styles/main.scss';

.mySwiper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 32px;
    
    & > div {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 190px;
        @include md {
            height: 280px;
        }
    }

    &__skeleton-container {
        display: flex !important;
        flex-direction: row !important;
        border-radius: none !important;
        justify-content: space-between !important;
        align-items: center !important;
        justify-self: center !important;

        &--each {
            width: 32.5% !important;
            display: flex !important;
            flex-direction: column !important;
            align-items: center !important;
            justify-content: center !important;
            gap: 10px !important;

            &--main-skeleton {
                height: 130px !important;
                @include md {
                    height: 200px !important;
                }
            }
        }
    }

    &__img {
        @include md {
            // height: 280px;
            padding-bottom: 40px;
            &.default-img {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: center;
            }
        }
        img {
            border-radius: 6px;

            @include md {
                max-height: 280px;
            }
        }
        .img-default {
            width: 100% !important;

            @include md {
                    max-width: 350px;
            }
        }

        &--ppal {
            padding-top: 16px;
            accent-color: $primary;
            font-size: 15px;
            display: flex;
            justify-content: center;
            gap: 5px;
            cursor: pointer;

            input {
                cursor: pointer;
            }
        }
    }
}

.swiper {
    height: 100%;
}

.swiper-slide {
    align-self: center;
    text-align: center;
    font-size: 18px;
    background: #fff;
    width: 100% !important;
    
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.swiper-slide {
    flex: 0 0 32.5%; 
}
