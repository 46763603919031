@import '../../../../styles/main.scss';

.vin-piezas-sidegal {
  .search-btn {
    height: 39px;
    min-width: 90px !important;
    width: 90px !important;
  }

  .total-piezas-results {
    font-weight: 300px !important;
    font-size: 18px !important;
    color: $fontTertiary !important;
  }

  .filter-sidegal {
    gap: 10px;
  }


}