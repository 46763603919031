// Table data grid styles
.data-grid-container {
  height: 600px;
  width: 100%;
  background-color: $background;
  box-shadow: 0 4px 6px -1px $boxShadow, 0 2px 4px -1px $boxShadow;
  border: 1px solid $light-gray;

  .MuiDataGrid-root {
    & .MuiDataGrid-columnHeaders {
      background-color: $primary !important; // Set background to primary color
      color: $white; // Set text color to white
      font-weight: bold;
      font-size: 16px;

      .MuiDataGrid-iconSeparator {
        display: none; // Hide the default icon separator if any
      }

      .MuiSvgIcon-root {
        color: $white; // Set the color of icons to white
      }
    }

    & .MuiDataGrid-row {
      &:hover {
        background-color: $bgHover;
      }
    }

    & .MuiDataGrid-selectedRowCount {
      color: $primaryHover;
    }
    

    & .MuiCheckbox-root {
      color: $checkBox;

      &.Mui-checked {
        color: $checkBoxCheked;
      }

      &.Mui-disabled {
        color: $checkBoxDisabled;
      }
    }

    & .MuiDataGrid-footerContainer {
      background-color: $off-white;
      color: $fontTertiary;
    }
  }
}
